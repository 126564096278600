import React from "react"
import ReactHtmlParser from 'react-html-parser'

import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../parts/layout"
import Seo from "../parts/seo"

const AboutPage = ({ data }) => (
  <Layout>
    <Seo title="About" />
    
    <section>
      <div className="row">
        <h1 className="page-title">{ data.strapiAbout.title }</h1>
      </div>
      { data.strapiAbout.rows.map(row =>
        <div key={row.id} className="row"><div className={ `columns-${row.columns.length}` }>
          { row.columns.map(column => <div key={column.id} className="column">
            { ReactHtmlParser(column.content) }
          </div>) }
        </div></div>
      ) }
    </section>
    <section id="colofon">
      <div className="row">
        <h2 className="center">Colophon</h2>
        <div id="colofon-items">
          { data.strapiAbout.colofon.map(item => {
            return (
              <a key={item.id} className="colofon-item" href={item.link} target="_blank" rel="noopener noreferrer">
                <div className="logo">
                  { item.logo && <GatsbyImage
                    image={item.logo.localFile.childImageSharp.gatsbyImageData}
                    alt={item.logo.alternativeText}
                    imgStyle={{ objectFit: "contain" }} /> }
                </div>
                {/* <h3>{ item.title }</h3> */}
              </a>
            );
          }) }
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutPage


export const pageQuery = graphql`query AboutQuery {
  strapiAbout {
    title
    rows {
      id
      columns {
        id
        content
      }
    }
    colofon {
      id
      title
      link
      logo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 200, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`
